




















































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "GenerateTkButtons",
  data() {
    return {
      isLoading: false,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tkGeneration", ["fieldFilters", "isHasGeneratedTk"]),
    isDisableTkGenerateBtn() {
      return !Object.entries(this.fieldFilters as Record<string, string | string[]>)
        .filter(([key]) => {
          const customKeys: string[] = this.fieldFilters[fields.IS_CUSTOM_FILE] ? [fields.MAIN_FILE, fields.INVENTORY_NUMBER] : [fields.FILE_TYPE];
          const mrdKeys: string[] = this.fieldFilters[fields.ADD_MRD] ? [fields.TRUSTED_CERT, fields.REPRESENTATIVE_CERT] : [];
          return customKeys.concat(mrdKeys).concat([fields.OIK, fields.EAD_NUMBER]).includes(key);
        })
        .every(([, item]) => {
          if (Array.isArray(item)) {
            return item.length;
          }
          return !!item;
        });
    },
    isEpcAuthorityExist(): boolean {
      return isAuthorityExist(this.user, authorities.EXPERT_COMMISSION_LIST);
    },
  },
  mounted() {
    this.getHasGeneratedTk();
  },
  methods: {
    ...mapActions("tkGeneration", ["generateTk", "clearGeneratedTkFolder", "getHasGeneratedTk", "moveGeneratedTk"]),
    async startGenerateTk() {
      try {
        this.isLoading = true;
        const isStartGenerate = await this.generateTk();
        if (isStartGenerate) {
          showNotification("Генерация ТК успешно запущена", NOTIFICATION_STATUS.SUCCESS);
          await this.getHasGeneratedTk();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async clearTkFolder() {
      const isCleared = await this.clearGeneratedTkFolder();
      if (isCleared) {
        showNotification("Очистка папки ТК успешно завершена", NOTIFICATION_STATUS.SUCCESS);
        await this.getHasGeneratedTk();
      }
    },
    async moveTk(section: string) {
      const isMoved = await this.moveGeneratedTk(section);
      if (isMoved) {
        showNotification("Перемещение ТК успешно завершено", NOTIFICATION_STATUS.SUCCESS);
        await this.getHasGeneratedTk();
      }
    },
    openModal(isGenerateBtn: boolean, section = "") {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "GenerateTkModal", {
          isGenerateBtn,
          cbOnConfirmAction: isGenerateBtn ? this.startGenerateTk.bind(this, section) : this.moveTk.bind(this, section),
        })
      );
    },
    openApproveModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.clearTkFolder,
          title: "Подтвердите удаление",
        })
      );
    },
  },
});
