




















































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import moment from "moment";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export default defineComponent({
  name: "GenerateAutomateTask",
  data() {
    return {
      isLoading: false,
      isLoadingIndexing: false,
      educationIds,
      year: moment().year() + 1,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isNeedJobStarterCreate(): boolean {
      return isAuthorityExist(this.user, authorities.JOB_STARTER_CREATE);
    },
  },
  methods: {
    ...mapActions("tkGeneration", ["startTransferEndJob", "startTransferHistoryJob", "startOikUpdateJob", "startIndexingJob"]),
    async startAction(action: string, params = {}) {
      if (action === "startIndexingJob") {
        this.isLoadingIndexing = true;
      } else {
        this.isLoading = true;
      }
      const response = await this[action as "startTransferEndJob" | "startTransferHistoryJob" | "startOikUpdateJob" | "startIndexingJob"](params);
      if (response?.data) {
        showNotification("Успешно запущено", NOTIFICATION_STATUS.SUCCESS);
      }
      if (action === "startIndexingJob") {
        this.isLoadingIndexing = false;
      } else {
        this.isLoading = false;
      }
    },
    openModal(action: string, params = {}) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          icon: "",
          yesBtnTitle: "Да",
          cbOnDelete: () => this.startAction(action, params),
          title: "Подтвердите действие?",
        })
      );
    },
  },
});
