export const statusesSelectItems = [
  {
    bool: true,
    title: "Подтверждён",
    value: "Подтверждён",
  },
  {
    bool: false,
    title: "Отклонён",
    value: "Отклонён",
  },
];
