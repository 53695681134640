
























import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "GenerateNomenclatureButton",
  data() {
    return {
      isLoading: false,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("tkGeneration", ["fieldFilters", "isHasGeneratedTk"]),
    isDisableTkGenerateBtn() {
      return !Object.entries(this.fieldFilters as Record<string, string | string[]>)
        .filter(([key]) => {
          return [
            fields.FILTERS_NOMENCLATURE_OIK,
            fields.FILTERS_NOMENCLATURE_YEAR,
            fields.FILTERS_NOMENCLATURE_SECTION,
            fields.FILTERS_NOMENCLATURE_CASE,
          ].includes(key);
        })
        .every(([, item]) => {
          if (Array.isArray(item)) {
            return item.length;
          }
          return !!item;
        });
    },
  },
  methods: {
    ...mapActions("tkGeneration", ["generateNomenclature", "moveGeneratedTk", "clearGeneratedTkFolder", "getHasGeneratedTk"]),
    openModalTransfer(isGenerateBtn: boolean, section = "") {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "GenerateTkModal", {
          isGenerateBtn,
          cbOnConfirmAction: this.moveTk.bind(this, section),
        })
      );
    },
    async moveTk(section: string) {
      const isMoved = await this.moveGeneratedTk(section);
      if (isMoved) {
        showNotification("Перемещение ТК успешно завершено", NOTIFICATION_STATUS.SUCCESS);
        await this.getHasGeneratedTk();
      }
    },
    async clearTkFolder() {
      const isCleared = await this.clearGeneratedTkFolder();
      if (isCleared) {
        showNotification("Очистка папки ТК успешно завершена", NOTIFICATION_STATUS.SUCCESS);
        await this.getHasGeneratedTk();
      }
    },
    openApproveModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.clearTkFolder,
          title: "Подтвердите удаление",
        })
      );
    },
    openModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          yesBtnTitle: "Да",
          icon: "",
          heightModal: 216,
          title: "Вы уверены, что хотите сгенерировать Номенклатуру? После подтверждения будет начат процесс генерации Номенклатуры",
          cbOnDelete: this.generateNomenclature,
        })
      );
    },
  },
});
