





























































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "Footer",
  data() {
    return {
      isLoading: false,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isNeedDelete(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_IB);
    },
    isNeedAddDefaultCatalogs(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_LOG_UPDATE_MODIFY);
    },
  },
  methods: {
    ...mapActions("tkGeneration", ["addDefaultItems", "deleteAllDictionaries", "deleteAllItems", "deleteTKInformation"]),
    openModal(name: "addDefaultItems" | "deleteAllDictionaries" | "deleteAllItems" | "deleteTKInformation") {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: () => this.doAction(name),
          title: "Подтвердите удаление",
        })
      );
    },
    openApproveModal(name: "addDefaultItems" | "deleteAllDictionaries" | "deleteAllItems" | "deleteTKInformation") {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "WarningModal", {
          cbOnDelete: () => this.doAction(name),
          title: "Вы уверены, что хотите добавить справочники по умолчанию?",
        })
      );
    },
    async doAction(name: "addDefaultItems" | "deleteAllDictionaries" | "deleteAllItems" | "deleteTKInformation") {
      try {
        this.isLoading = true;
        await this[name]?.();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
