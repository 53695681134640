





























import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "GenerateTkButtonsFromOIK",
  data() {
    return {
      isLoading: false,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tkGeneration", ["fieldFilters", "isHasGeneratedTk"]),
    isDisableTkGenerateBtn() {
      return !Object.entries(this.fieldFilters as Record<string, string | string[]>)
        .filter(([key]) => {
          return [fields.OIK_GENERATE_TK, fields.OIK_GENERATE_CODE_RESPONSE, fields.OIK_GENERATE_TEXT].includes(key);
        })
        .every(([, item]) => {
          if (Array.isArray(item)) {
            return item.length;
          }
          return !!item;
        });
    },
  },
  methods: {
    ...mapActions("tkGeneration", [
      "getExportData",
      "deleteElement",
      "generateOikTk",
      "moveGeneratedTk",
      "clearGeneratedTkFolder",
      "getHasGeneratedTk",
    ]),
    async startGenerateTk() {
      this.isLoading = true;
      const isStartGenerate = await this.generateOikTk();
      if (isStartGenerate) {
        showNotification("Генерация ТК успешно запущена", NOTIFICATION_STATUS.SUCCESS);
        // await this.getHasGeneratedTk();
      }
      this.isLoading = false;
    },
    openModal(isGenerateBtn: boolean) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "GenerateTkModal", {
          isGenerateBtn,
          cbOnConfirmAction: this.startGenerateTk,
        })
      );
    },
    openApproveModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.clearTkFolder,
          title: "Подтвердите удаление",
        })
      );
    },
  },
});
