




















































import { defineComponent, ref } from "@vue/composition-api";
import { currentOIKFilters } from "../utils/defaultCurrentFilters";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/tkGeneration/viewTitle";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/tkGeneration/filtersElements";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import moment from "moment";
import eventBus from "@monorepo/utils/src/eventBus";
import useFilter from "@monorepo/utils/src/composables/useFilter";

export default defineComponent({
  name: "FiltersTkGenerationFromOIK",
  components: {
    TextFilter,
    DatePicker,
    SelectFilter,
  },
  data() {
    return {
      filters: currentOIKFilters(),
      filtersElements,
      viewUniqKey,
      fields,
    };
  },
  computed: {
    ...mapGetters("tkGeneration", ["fieldFilters", "responseCodes", "oikCodes", "isTableLoading"]),
    oikCodesSelectItems(): { title: string; value: string }[] {
      return this.oikCodes.map((item: { oikShortName: string; transferDate: string; tkGuid: string }) => ({
        title: `${item.oikShortName} - ${moment(item.transferDate).format("YYYY-MM-DD HH:mm")}`,
        value: item.tkGuid,
      }));
    },
    responseCodesSelectItems(): { title: string; value: string }[] {
      return convertToSelectItems(this.responseCodes);
    },
  },
  methods: {
    ...mapActions("tkGeneration", ["clearFilters", "addFilter"]),
    addFilterInputValue(key: string, value: string) {
      this.filters[key] = value;
      this.addFilter(this.filters);
    },
    clearAllFilters({ id }: { id: number }) {
      if (id === 26) {
        this.filters = currentOIKFilters();
        this.addFilter(this.filters);
      }
    },
  },
  mounted() {
    this.addFilter(this.filters);
  },
  beforeMount() {
    eventBus.$on("newNotification", this.clearAllFilters);
  },
  beforeDestroy() {
    eventBus.$off("newNotification", this.clearAllFilters);
  },
  setup(props, context) {
    const currentFilters = ref({});
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const { filter } = useFilter(
      context,
      { value: true },
      "tkGeneration",
      {},
      currentFilters,
      () => ({}),
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );

    return {
      filter,
    };
  },
});
