
























import { defineComponent, ref } from "@vue/composition-api";
import { currentInvFilters } from "../utils/defaultCurrentFilters";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/tkGeneration/viewTitle";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/tkGeneration/filtersElements";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import eventBus from "@monorepo/utils/src/eventBus";
import useFilter from "@monorepo/utils/src/composables/useFilter";

export default defineComponent({
  name: "FiltersTkInventory",
  components: {
    SelectFilter,
  },
  data() {
    return {
      filters: currentInvFilters(),
      filtersElements,
      viewUniqKey,
      fields,
    };
  },
  computed: {
    ...mapGetters("tkGeneration", ["fieldFilters", "inventories", "isTableLoading", "isLoadedInventories"]),
    inventoriesResult(): { title: string; value: string }[] {
      return convertToSelectItems(this.inventories, "header", "id");
    },
  },
  methods: {
    ...mapActions("tkGeneration", ["clearFilters", "addFilter"]),
    clearAllFilters({ id }: { id: number }) {
      if (id === 26) {
        this.filters = currentInvFilters();
        this.addFilter(this.filters);
      }
    },
  },
  mounted() {
    this.addFilter(this.filters);
  },
  beforeMount() {
    eventBus.$on("newNotification", this.clearAllFilters);
  },
  beforeDestroy() {
    eventBus.$off("newNotification", this.clearAllFilters);
  },
  setup(props, context) {
    const currentFilters = ref({});
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const { filter } = useFilter(
      context,
      { value: true },
      "tkGeneration",
      {},
      currentFilters,
      () => ({}),
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );

    return {
      filter,
    };
  },
});
