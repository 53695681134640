

















import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "GenerateNsiButton",
  data() {
    return {
      isLoading: false,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("tkGeneration", ["fieldFilters"]),
    isDisableNsiBtn() {
      return !Object.entries(this.fieldFilters as Record<string, string | string[]>)
        .filter(([key]) => {
          return [fields.FILTERS_NSI_TK, fields.FILTERS_NSI_STATUS].includes(key);
        })
        .every(([, item]) => {
          return !!item;
        });
    },
  },
  methods: {
    ...mapActions("tkGeneration", ["generateNsi"]),
    async generateNsiCb() {
      this.isLoading = true;
      await this.generateNsi();
      this.isLoading = false;
    },
    openModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          yesBtnTitle: "Да",
          icon: "",
          heightModal: 216,
          title: "Вы уверены, что хотите сгенерировать ТК с ответом от ГАС НСИ?",
          cbOnDelete: this.generateNsiCb,
        })
      );
    },
  },
});
