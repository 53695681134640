





































import { defineComponent, ref } from "@vue/composition-api";
import { currentNsiFilters as setCurrentFilters } from "../utils/defaultCurrentFilters";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/tkGeneration/viewTitle";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/tkGeneration/filtersElements";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import eventBus from "@monorepo/utils/src/eventBus";
import useFilter from "@monorepo/utils/src/composables/useFilter";
import { statusesSelectItems } from "@monorepo/administration/src/views/TkGeneration/constants/tkStatuses";

export default defineComponent({
  components: {
    SelectFilter,
  },
  data() {
    return {
      filters: setCurrentFilters(),
      filtersElements,
      viewUniqKey,
      fields,
      statusesSelectItems,
    };
  },
  computed: {
    ...mapGetters("tkGeneration", ["fieldFilters", "tkList", "isTableLoading"]),
    tkSelectItems(): { title: string; value: string }[] {
      return convertToSelectItems(this.tkList, "tkGuid", "tkGuid");
    },
  },
  beforeMount() {
    eventBus.$on("newNotification", this.clearAllFilters);
  },
  beforeDestroy() {
    eventBus.$off("newNotification", this.clearAllFilters);
  },
  methods: {
    ...mapActions("tkGeneration", ["clearFilters", "addFilter"]),
    clearAllFilters({ id }: { id: number }) {
      if (id === 26) {
        this.filters = setCurrentFilters();
        this.addFilter(this.filters);
      }
    },
    setCurrentFilters,
  },
  mounted() {
    this.addFilter(this.filters);
  },
  setup(props, context) {
    const currentFilters = ref({});
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const { filter } = useFilter(
      context,
      { value: true },
      "tkGeneration",
      {},
      currentFilters,
      () => ({}),
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );

    return {
      filter,
    };
  },
});
