import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;

  onBeforeMount(() => {
    store.dispatch("tkGeneration/getOiks");
    store.dispatch("tkGeneration/getTkList");
    store.dispatch("tkGeneration/getFileTypes");
    store.dispatch("tkGeneration/getResponseCodes");
    store.dispatch("tkGeneration/getOikCodes");
    store.dispatch("tkGeneration/getInventories");
    store.dispatch("tkGeneration/getCertificates");
  });
};

export default useGetTableLibs;
