



















































































import { defineComponent, ref } from "@vue/composition-api";
import { currentNomenclatureFilters as setCurrentFilters } from "../utils/defaultCurrentFilters";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import CheckboxElement from "@monorepo/uikit/src/components/Settings/CheckboxElement.vue";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/tkGeneration/viewTitle";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/tkGeneration/filtersElements";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import eventBus from "@monorepo/utils/src/eventBus";
import useFilter from "@monorepo/utils/src/composables/useFilter";

export default defineComponent({
  components: {
    TextFilter,
    DatePicker,
    CheckboxElement,
    SelectFilter,
  },
  data() {
    return {
      filters: setCurrentFilters(),
      filtersElements,
      viewUniqKey,
      fields,
    };
  },
  computed: {
    ...mapGetters("tkGeneration", ["fieldFilters", "fileTypes", "oiks", "isTableLoading"]),
    years(): { title: string; value: string }[] {
      return convertToSelectItems(Array.from({ length: 20 }, (_, i) => (new Date().getFullYear() - i).toString()));
    },
    oiksSelectItems(): { title: string; value: string }[] {
      return convertToSelectItems(this.oiks, "shortName", "code");
    },
  },
  beforeMount() {
    eventBus.$on("newNotification", this.clearAllFilters);
  },
  beforeDestroy() {
    eventBus.$off("newNotification", this.clearAllFilters);
  },
  methods: {
    ...mapActions("tkGeneration", ["clearFilters", "addFilter"]),
    clearAllFilters({ id }: { id: number }) {
      if (id === 26) {
        this.filters = setCurrentFilters();
        this.addFilter(this.filters);
      }
    },
    setCurrentFilters,
    addFilterInputValue(key: string, value: string) {
      this.filters[key] = value;
      this.addFilter(this.filters);
    },
  },
  mounted() {
    this.addFilter(this.filters);
  },
  setup(props, context) {
    const currentFilters = ref({});
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const { filter } = useFilter(
      context,
      { value: true },
      "tkGeneration",
      {},
      currentFilters,
      () => ({}),
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );

    return {
      filter,
    };
  },
});
