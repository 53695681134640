











































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "GenerateTkButtons",
  data() {
    return {
      isLoading: false,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tkGeneration", ["fieldFilters", "isHasGeneratedTk"]),
    isDisableTkGenerateBtn() {
      if (this.fieldFilters.ercId && this.fieldFilters.ercId.includes("_")) {
        return true;
      }
      return !Object.entries(this.fieldFilters as Record<string, string | string[]>)
        .filter(([key]) => {
          return [
            fields.FILTERS_ERC_PROTOCOLS_OIK,
            fields.FILTERS_ERC_PROTOCOLS_FILE_TYPE,
            fields.FILTERS_ERC_PROTOCOLS_MIN_SIZE,
            fields.FILTERS_ERC_PROTOCOLS_MAX_SIZE,
            fields.FILTERS_ERC_PROTOCOLS_APPLICATIONS_NUMBER,
            fields.FILTERS_ERC_PROTOCOLS_INVENTORY_NUMBER,
            fields.FILTERS_ERC_PROTOCOLS_EAD_NUMBER,
            fields.FILTERS_ERC_PROTOCOLS_ID,
            fields.FILTERS_ERC_PROTOCOLS_VERSION,
          ].includes(key);
        })
        .every(([, item]) => {
          if (Array.isArray(item)) {
            return item.length;
          }
          return !!item;
        });
    },
    isEpcAuthorityExist(): boolean {
      return isAuthorityExist(this.user, authorities.EXPERT_COMMISSION_LIST);
    },
  },
  mounted() {
    this.getHasGeneratedTk();
  },
  methods: {
    ...mapActions("tkGeneration", [
      "getExportData",
      "deleteElement",
      "generateTk",
      "generateERCProtocolsTk",
      "moveGeneratedTk",
      "clearGeneratedTkFolder",
      "getHasGeneratedTk",
    ]),
    async startGenerateTk(section: string) {
      this.isLoading = true;
      const isStartGenerate = await this[section === "INVENTORY_PROJECT" ? "generateERCProtocolsTk" : "generateTk"]();
      if (isStartGenerate) {
        showNotification("Генерация ТК успешно запущена", NOTIFICATION_STATUS.SUCCESS);
        await this.getHasGeneratedTk();
      }
      this.isLoading = false;
    },
    async moveTk(section: string) {
      const isMoved = await this.moveGeneratedTk(section);
      if (isMoved) {
        showNotification("Перемещение ТК успешно завершено", NOTIFICATION_STATUS.SUCCESS);
        await this.getHasGeneratedTk();
      }
    },
    async clearTkFolder() {
      const isCleared = await this.clearGeneratedTkFolder();
      if (isCleared) {
        showNotification("Очистка папки ТК успешно завершена", NOTIFICATION_STATUS.SUCCESS);
        await this.getHasGeneratedTk();
      }
    },
    openModal(isGenerateBtn: boolean, section = "") {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "GenerateTkModal", {
          isGenerateBtn,
          cbOnConfirmAction: isGenerateBtn ? this.startGenerateTk.bind(this, section) : this.moveTk.bind(this, section),
        })
      );
    },
    openApproveModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.clearTkFolder,
          title: "Подтвердите удаление",
        })
      );
    },
  },
});
