




















































































































































import Filters from "@monorepo/administration/src/views/TkGeneration/components/Filters.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import GenerateTkButtons from "@monorepo/administration/src/views/TkGeneration/components/GenerateTkButtons.vue";
import GenerateTkInventoriesButtons from "@monorepo/administration/src/views/TkGeneration/components/GenerateTkInventoriesButtons.vue";
import GenerateTkButtonsFromOIK from "@monorepo/administration/src/views/TkGeneration/components/GenerateTkButtonsFromOIK.vue";
import GenerateAutomateTask from "@monorepo/administration/src/views/TkGeneration/components/GenerateAutomateTask.vue";
import GenerateTkERCProtocolsButtons from "@monorepo/administration/src/views/TkGeneration/components/GenerateTkERCProtocolsButtons.vue";
import GenerateNomenclatureButton from "@monorepo/administration/src/views/TkGeneration/components/GenerateNomenclatureButton.vue";
import GenerateNsiButton from "@monorepo/administration/src/views/TkGeneration/components/GenerateNsiButton.vue";
import FiltersTkGenerationFromOIK from "@monorepo/administration/src/views/TkGeneration/components/FiltersTkGenerationFromOIK.vue";
import FiltersTkInventory from "@monorepo/administration/src/views/TkGeneration/components/FiltersTkInventory.vue";
import FiltersNomenclature from "@monorepo/administration/src/views/TkGeneration/components/FiltersNomenclature.vue";
import FiltersNsi from "@monorepo/administration/src/views/TkGeneration/components/FiltersNsi.vue";
import FiltersERCProtocols from "@monorepo/administration/src/views/TkGeneration/components/FiltersERCProtocols.vue";
import Footer from "@monorepo/administration/src/views/TkGeneration/components/Footer.vue";
import {
  viewTitle,
  viewSecondTitle,
  viewThirdTitle,
  viewSixthTitle,
  viewFifthTitle,
  viewFourthTitle,
  viewUniqKey,
  viewSeventhTitle,
} from "@monorepo/utils/src/variables/projectsData/tkGeneration/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as tkGeneration } from "@monorepo/administration/src/store/modules/tkGeneration";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/administration/src/views/TkGeneration/constants/breadcrumbs";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import useGetTableLibs from "./composables/useGetTableLibs";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";

const SECTION_NAME = "tkGeneration";

export default defineComponent({
  name: "TkGenerationView",
  components: {
    Filters,
    Breadcrumbs,
    GenerateTkButtons,
    Footer,
    FiltersTkGenerationFromOIK,
    GenerateNomenclatureButton,
    FiltersTkInventory,
    GenerateTkInventoriesButtons,
    FiltersNomenclature,
    GenerateTkERCProtocolsButtons,
    FiltersERCProtocols,
    GenerateAutomateTask,
    GenerateTkButtonsFromOIK,
    GenerateNsiButton,
    FiltersNsi,
  },
  data() {
    return {
      breadcrumbs,
      viewThirdTitle,
      viewSixthTitle,
      viewSeventhTitle,
      viewTitle,
      viewFourthTitle,
      viewFifthTitle,
      viewSecondTitle,
      viewUniqKey,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, ["data", "totalLength", "isTableLoading"]),
    isNeedRefreshCatalog(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_LOG_UPDATE_MODIFY);
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData"]),
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, tkGeneration as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);

    useSetStartFilters(root, SECTION_NAME, [fields.OIK]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
    });
    useGetTableLibs(root);

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
